import React from 'react';
import Layout from '../layouts/Layout';
import {
  Section,
  Container,
  Row,
  Column,
  Share,
  List,
  ListItem,
  Button,
} from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { getImageUrl } from '../utils';
import { Text } from '../components/Text';
import { Tag } from '../components/Tag';
import { Link } from 'gatsby-plugin-react-i18next';
import { BannerSection } from '../components/banner/BannerSection.carware';
import { NavButton } from '@nimles/react-web-components-redux';
import { graphql } from 'gatsby';

const Logo = styled.img`
  margin-bottom: 30px;
  width: 120px;
`;

const Category = ({ pageContext, location }) => {
  const { href } = location;
  const { productGroup } = pageContext;
  const {
    id,
    name,
    summary,
    description,
    logoId,
    bannerId,
    tags,
    children,
  } = productGroup;

  const banner = getImageUrl(bannerId, 1920);
  const logo = getImageUrl(logoId, 480);

  const meta = {
    title: name,
    description: summary,
    image: banner,
    keywords: tags?.join(', '),
  };

  return (
    <Layout
      header
      footer
      meta={meta}
      location={location}
      headerAlwaysOpen={true}
    >
      <BannerSection round></BannerSection>
      <Section>
        <Container>
          <Row wrap="wrap" justify="space-between" align="center">
            <Column></Column>
            <Column>
              <Share
                href={href}
                title={productGroup.title}
                description={productGroup.summary}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <h1>{name}</h1>
          <h2>{summary}</h2>
          <Row>
            <Column>
              <NavButton
                primary
                raised
                to={`/requestquote/?productGroupId=${id}`}
              >
                Begär offert
              </NavButton>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Container>
      </Section>
      <Section>
        <Container>
          <Row wrap="wrap">
            <Column xs={100} justify="center">
              <Text>
                {logoId ? (
                  <Row justify="center">
                    <Column>
                      <Logo src={logo} alt={name} />
                    </Column>
                  </Row>
                ) : null}
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Text>
            </Column>
            <Column xs={100} md={50} lg={40} justify="center"></Column>
          </Row>
          <Row wrap="wrap">
            <List>
              {children?.slice(0, 5).map(({ id, name, uniqueName }) => (
                <ListItem key={id}>
                  <Link to={`/${uniqueName}-pg`}>{name}</Link>
                </ListItem>
              ))}
            </List>
          </Row>
          <Row justify="center" wrap="wrap" margin="0 -10px">
            {tags?.sort().map((tag) => (
              <Column>
                <Tag>{tag}</Tag>
              </Column>
            ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
